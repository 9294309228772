import {extend} from 'flarum/extend';
import app from 'flarum/app';
import LogInModal from 'flarum/components/LogInModal';


app.initializers.add('flagrow-direct-links', () => {
    let __query = function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2])
        }
        return null
    };
    if (__query("from")) {
        let num = 0;
        let inter = setInterval(() => {
            if (num > 100 || (typeof app != "undefined" && typeof app.modal != "undefined" && typeof app.modal.show != "undefined")) {
                clearInterval(inter);
                app.modal.show(new LogInModal());
            }
            num++;
        }, 100);
    }
});
